import React, { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ROUTES from "../services/AppConstant";
import { Input } from "../components/ui/input";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TEXT } from "../constant/textConstants";
import { useForm, Controller } from "react-hook-form";
import { API_CONSTANT } from "../services/ApiConstant";
import { postWithOutToken } from "../services/ApiService";
import { SESSION_KEY, setInAsyncStorage } from "../lib/utils";
import { Eye, User, EyeOff, LockKeyhole, Loader2 } from "lucide-react";

const Login = () => {
  const navigate = useNavigate();
  const { bookOrders } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const loginApiCall = async (data) => {
    try {
      const res = await postWithOutToken(`${API_CONSTANT?.LOGIN}`, {
        ...data,
        IPAddress: "",
      });

      if (res?.data?.IsLoggedIn) {
        setInAsyncStorage(SESSION_KEY.USER_DATA, res?.data);
        toast.success(res?.data?.Message || "Login Successfully");

        if (bookOrders?.bookOrders && bookOrders?.bookOrders?.length > 0) {
          navigate(ROUTES.CHECK_OUT);
        } else {
          navigate(ROUTES.HOME);
        }
      } else {
        toast.error(res?.data?.Message);
      }
    } catch (err) {
      console.error("Error during login API call:", err);
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      reset();
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    await loginApiCall(data);
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100 py-6">
      <main className="flex w-full flex-col items-center justify-center px-5 text-center sm:px-12 md:px-10 lg:px-20">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex w-full max-w-4xl flex-col rounded-2xl bg-white shadow-lg md:w-[90%] md:flex-row"
        >
          {/* Left Section */}
          <div className="flex w-full flex-col justify-center px-6 py-10 md:w-1/2 md:px-9 lg:px-11">
            <p className="text-black-1-1 text-2xl font-bold md:text-3xl">
              {t(TEXT?.LOGIN).toUpperCase()}
            </p>
            <p className="mt-2 text-sm text-gray-500 md:text-base">
              {t(TEXT?.ENTER_USERNAME_PASSWORD)}
            </p>

            <div className="mt-4">
              <div className="flex h-[50px] items-center rounded-2xl bg-purple-3 md:h-[52px]">
                <User className="ml-5 mr-2 text-black-1" />
                <Controller
                  name="UserName"
                  defaultValue=""
                  control={control}
                  rules={{
                    required: "Username is required",
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t(TEXT?.USERNAME)}
                      className="flex-grow border-none bg-transparent !p-0 !pr-5 text-black-1 shadow-none focus:outline-none focus-visible:ring-0"
                    />
                  )}
                />
              </div>
              <div className="ml-4 mt-1 min-h-[20px] text-left text-sm text-red-500">
                {errors.UserName && <span>{errors.UserName.message}</span>}
              </div>

              {/* Password Field */}
              <div className="mt-1 flex h-[50px] items-center rounded-2xl bg-purple-3 md:h-[52px]">
                <LockKeyhole className="ml-5 mr-2 text-black-1" />
                <Controller
                  name="Password"
                  defaultValue=""
                  control={control}
                  rules={{
                    required: "Password is required",
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder={t(TEXT?.PASSWORD)}
                      type={showPassword ? "text" : "password"}
                      className="flex-grow border-none bg-transparent !p-0 text-black-1 shadow-none focus:outline-none focus-visible:ring-0"
                    />
                  )}
                />
                <div
                  className="cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff className="ml-2 mr-4 text-gray-500" />
                  ) : (
                    <Eye className="ml-2 mr-4 text-gray-500" />
                  )}
                </div>
              </div>
              <div className="ml-4 mt-1 min-h-[20px] text-left text-sm text-red-500">
                {errors.Password && <span>{errors.Password.message}</span>}
              </div>

              {/* Login Button */}
              <button
                type="submit"
                className={`${loading ? "bg-gray-400 py-[10px]" : "bg-login-btn-redial py-[14px]"} mt-[2px] w-36 rounded-xl px-9 text-sm font-bold text-white transition hover:shadow-xl`}
              >
                {loading ? (
                  <Loader2 className="mx-5 h-7 w-7 animate-spin" />
                ) : (
                  `${t(TEXT?.LOGIN)}`
                )}
              </button>
            </div>
          </div>

          {/* Right Section */}
          <div className="hidden h-[550px] w-full items-center justify-center rounded-br-2xl rounded-tr-2xl bg-login-bg bg-cover bg-center bg-no-repeat text-white md:flex md:w-1/2" />
        </form>
      </main>
    </div>
  );
};

export default Login;
