import React from "react";
import {
  Hash,
  Bookmark,
  BookOpen,
  Calendar,
  PlusIcon,
  MinusIcon,
} from "lucide-react";
import { Card } from "../ui/card";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ROUTES from "../../../src/services/AppConstant";
import { TEXT } from "../../../src/constant/textConstants";
import { setSelectedBook } from "../../../src/store/orders/reducer";

const BookOrderComponent = ({
  cart,
  bookList,
  handlePostOrder,
  handleAddToCart,
  handleQuantityChange,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className="my-5 flex h-[550px] flex-col bg-white p-4">
      <p className="mb-3 text-2xl">
        {t(TEXT?.SEARCH)} {t(TEXT?.LIST)}
      </p>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto">
        {bookList?.map((book) => (
          <Card key={book.ISBN10} className="mb-3 p-3">
            <div className="flex gap-6">
              <div className="h-auto w-36 flex-shrink-0 rounded bg-muted">
                <img
                  alt="Book cover"
                  className="h-full w-full rounded object-cover"
                  src="/icons/book/book_placeholder.svg?height=600&width=400"
                />
              </div>

              <div className="min-w-0 flex-1">
                <div
                  onClick={() => {
                    window.open(ROUTES?.BOOK_DETAILS, "_blank");
                    dispatch(setSelectedBook(book));
                  }}
                  className="group flex w-full cursor-pointer items-start justify-between gap-4"
                >
                  <h2 className="w-max text-lg font-semibold leading-tight transition-colors group-hover:text-purple-1">
                    {book.Title}
                  </h2>
                </div>

                {(book.Price || book.AvailabilityDays) && (
                  <div className="mt-1 flex flex-wrap items-center gap-2">
                    {book.Price && (
                      <span className="mt-0.5 text-base font-medium">
                        {book.Price}
                      </span>
                    )}
                    {book.AvailabilityDays && (
                      <Badge
                        variant="outline"
                        className="border-purple-1 !text-xs text-purple-1"
                      >
                        {t(TEXT?.SHIPS_IN)} {book.AvailabilityDays}{" "}
                        {t(TEXT?.DAYS)}
                      </Badge>
                    )}
                  </div>
                )}

                {book.Author && (
                  <div className="mt-1 flex items-center text-sm text-muted-foreground">
                    {book.Author && (
                      <div className="flex items-center gap-1">
                        <BookOpen className="h-4 w-4" />
                        {`By ${book.Author}`}
                      </div>
                    )}
                  </div>
                )}

                {(book.PublicationDate ||
                  book.ISBN10 ||
                  book.ISBN13 ||
                  book.Binding) && (
                  <div className="mt-1 flex flex-wrap items-center gap-2 text-sm text-muted-foreground">
                    {book.PublicationDate && (
                      <div className="flex items-center gap-1">
                        <Calendar className="h-4 w-4" />
                        {book.PublicationDate}
                      </div>
                    )}
                    {(book.ISBN10 || book.ISBN13 || book.Binding) && (
                      <div className="flex flex-wrap items-center gap-2">
                        {(book.ISBN10 || book.ISBN13) && (
                          <div className="flex items-center gap-0.5">
                            <Hash className="h-4 w-4" />
                            <p>
                              <span className="mr-1 font-medium">ISBN:</span>
                              {book.ISBN10 && <span>{book.ISBN10}</span>}
                              {book.ISBN13 && (
                                <span className="ml-1">/ {book.ISBN13}</span>
                              )}
                            </p>
                          </div>
                        )}

                        {book.Binding && <span>( {book.Binding} )</span>}
                      </div>
                    )}
                  </div>
                )}

                {(book.SkuNumber || book.Publisher) && (
                  <div className="mt-1 flex flex-wrap items-center gap-2 text-sm text-muted-foreground">
                    {book.Publisher && (
                      <div className="flex flex-wrap items-center gap-1 text-sm text-muted-foreground">
                        <Bookmark className="h-4 w-4" />
                        <span className="font-medium">
                          {t(TEXT?.PUBLISHER)}:
                        </span>{" "}
                        {book.Publisher}
                      </div>
                    )}
                    {book.SkuNumber && (
                      <div className="flex items-center gap-1">
                        <Bookmark className="h-4 w-4" />
                        <span className="font-medium">
                          {t(TEXT?.SKU)}:
                        </span>{" "}
                        {book.SkuNumber}
                      </div>
                    )}
                  </div>
                )}

                <div className="mt-4 flex items-center justify-between gap-1 border-t pt-4">
                  <div className="flex items-center">
                    <div className="flex items-center rounded border">
                      <Button
                        size="icon"
                        variant="ghost"
                        className="h-8 w-8 rounded-none"
                        onClick={() => handleQuantityChange(book, -1)}
                      >
                        <MinusIcon className="h-3 w-3" />
                      </Button>
                      <span className="w-8 text-center text-sm">
                        {cart?.find((item) => item.ISBN10 === book.ISBN10)
                          ?.quantity || 0}
                      </span>
                      <Button
                        size="icon"
                        variant="ghost"
                        className="h-8 w-8 rounded-none"
                        onClick={() => handleQuantityChange(book, 1)}
                      >
                        <PlusIcon className="h-3 w-3" />
                      </Button>
                    </div>
                  </div>

                  <Button
                    onClick={() => handleAddToCart(book)}
                    disabled={book?.AvailableQty === 0}
                    className="mb-[1px] h-[33px] w-max bg-purple-1 hover:bg-purple-700"
                  >
                    {t(TEXT?.ADD_TO_CART)}
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>

      {cart && cart?.length > 0 && (
        <div className="flex justify-between border-t pt-4">
          <div className="flex items-center gap-2">
            <span className="text-sm font-medium">
              {cart?.length}{" "}
              {cart?.length === 1 ? `${t(TEXT?.ITEM)}` : `${t(TEXT?.ITEMS)}`}{" "}
              {t(TEXT?.FOR_ORDER)}
            </span>
          </div>
          <Button
            onClick={handlePostOrder}
            className="bg-purple-1 hover:bg-purple-700"
          >
            {t(TEXT?.CREATE_ORDER)}
          </Button>
        </div>
      )}
    </div>
  );
};

export default BookOrderComponent;
