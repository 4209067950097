import { Logo } from "./ui/icons";
import ROUTES from "../services/AppConstant";
import { useTranslation } from "react-i18next";
import { S_TEXT, TEXT } from "../constant/textConstants";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="m-auto max-w-7xl px-[15px] pb-3 pt-[100px] xl:px-0">
        <div className="flex flex-wrap items-start justify-between">
          <div className="flex w-full flex-wrap md:w-[80%]">
            <div className="pr-7 lg:w-[50%]">
              <div className="mb-[1rem]">
                <Logo className={"h-[70px] w-[250px]"} />
              </div>
              <p className="mb-[1rem] text-left text-lg text-dark-gray-1">
                {t(TEXT?.SOURCE_FOREIGN_BOOKS)}
              </p>
            </div>
            <div className="mt-4 md:mt-0 lg:w-[17.66%]">
              <div className="mb-[1rem] flex h-[58px] items-center text-[19px] font-bold text-dark-gray-1">
                {t(TEXT?.CONNECT_WITH_US)}
              </div>
              <div className="mb-[10px] text-[16px]">
                {S_TEXT?.SUPPORT_EMAIL}
              </div>
            </div>
            {/* <div className="md:w-[50%] md:pt-2">
              <div className=" text-[19px] text-dark-gray-1 font-bold mb-[25px]">
                Company
              </div>
              <a href={ROUTES?.CONTACT_US} className="text-[16px] mb-[10px]">
                Contact Us
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <footer className="mt-10 bg-gray-800 px-[15px] py-12 text-white xl:px-0">
        <div className="mx-auto flex max-w-7xl flex-col justify-between sm:flex-row sm:items-center">
          <span className="text-lg">{t(TEXT?.COPYRIGHT)}</span>
          <div className="mt-2 flex items-start space-x-4 sm:mt-0 sm:items-center">
            <a href={ROUTES?.CONTACT_US} className="text-lg">
              {t(TEXT?.CONTACT_US)}
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
