import { useState } from "react";
import { Loader2 } from "lucide-react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { API_CONSTANT } from "../services/ApiConstant";
import { postWithOutToken } from "../services/ApiService";
import { SESSION_KEY, getFromAsync } from "../lib/utils";
import { useTranslation } from "react-i18next";
import { TEXT } from "../constant/textConstants";

const ContactUs = () => {
  const userData = getFromAsync(SESSION_KEY?.USER_DATA);
  const AppUserId = userData && userData.AppUserId;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setLoading(true);
    const obj = {
      IPAddress: "",
      Name: data?.name,
      AppUserId: AppUserId,
      PhoneNumber: data?.phone,
      EmailAddress: data?.email,
      QueryInDetail: data?.question,
    };

    postWithOutToken(`${API_CONSTANT?.CONTACT_US}`, obj)
      .then((res) => {
        toast.success(res?.data?.Message);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <div className="pt-7 text-left">
      <div className="m-auto max-w-7xl p-[15px]">
        <div className="m-auto md:w-[79.332%]">
          <div className="flex w-full justify-center">
            <p className="text-center text-4xl font-semibold text-dark-gray-1 md:text-[53px]">
              {t(TEXT?.INTERESTED_LETS_TALK)}
            </p>
          </div>
          <div className="mt-[18px]">
            <p className="text-center text-xl text-dark-gray-1 md:text-[22px]">
              {t(TEXT?.DISCUSS_SPONDEES)}
            </p>
          </div>
        </div>
      </div>
      <div className="m-auto max-w-7xl p-[15px]">
        <div className="m-auto max-w-[600px]">
          <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className="mb-4">
              <label
                className="mb-1 block text-lg font-medium text-gray-700"
                htmlFor="name"
              >
                {t(TEXT?.YOUR)} {t(TEXT?.NAME)}:
              </label>
              <p className="mb-6 text-sm font-light">
                {t(TEXT?.ENTER_REAL_NAME)}:
              </p>
              <input
                id="name"
                type="text"
                placeholder={`${t(TEXT?.NAME)}*`}
                {...register("name", { required: true })}
                className="focus:shadow-outline h-14 w-full rounded-[6px] bg-lightBlue px-[15px] text-gray-700 shadow-[0_0_0_0] focus:outline-none"
              />
              {errors.name && (
                <p className="text-base text-red-400">
                  {t(TEXT?.FILL_OUT_FIELD)}
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="mb-1 block text-lg font-medium text-gray-700"
                htmlFor="name"
              >
                {t(TEXT?.YOUR)} {t(TEXT?.PHONE_NUMBER)}:
              </label>
              <p className="mb-6 text-sm font-light">
                {t(TEXT?.ENTER_PHONE_NUMBER)}:
              </p>
              <input
                id="phone"
                type="tel"
                placeholder={`${t(TEXT?.PHONE_NUMBER)}*`}
                {...register("phone", { required: true })}
                className="focus:shadow-outline h-14 w-full rounded-[6px] bg-lightBlue px-[15px] text-gray-700 shadow-[0_0_0_0] focus:outline-none"
              />
              {errors.phone && (
                <p className="text-base text-red-400">
                  {t(TEXT?.FILL_OUT_FIELD)}
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-lg font-medium text-gray-700"
                htmlFor="name"
              >
                {t(TEXT?.YOUR)} {t(TEXT?.EMAIL_ADDRESS)}:
              </label>
              <p className="mb-6 text-sm font-light">
                {t(TEXT?.CHECK_EMAIL_ACCURACY)}
              </p>
              <input
                id="email"
                type="email"
                placeholder={`${t(TEXT?.EMAIL_ADDRESS)}*`}
                {...register("email", { required: true })}
                className="focus:shadow-outline h-14 w-full rounded-[6px] bg-lightBlue px-[15px] text-gray-700 shadow-[0_0_0_0] focus:outline-none"
              />
              {errors.email && (
                <p className="text-base text-red-400">
                  {t(TEXT?.FILL_OUT_FIELD)}
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-lg font-medium text-gray-700"
                htmlFor="name"
              >
                {t(TEXT?.EXPLAIN_QUESTION_DETAILS)}:
              </label>
              <p className="mb-6 text-sm font-light">
                {/* Your OS version, Martex version & build, steps you did. Be VERY
                precise! */}
              </p>
              <textarea
                id="question"
                rows={4}
                placeholder={`${t(TEXT?.OUR_ECOMMERCE_STORE_NAME_IS)}  `}
                {...register("question", { required: false })}
                className="focus:shadow-outline w-full rounded-[6px] bg-lightBlue px-3 py-2 text-gray-700 shadow-[0_0_0_0] focus:outline-none"
              ></textarea>
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                disabled={loading}
                className="focus:shadow-outline mt-[15px] rounded bg-blue-600 px-10 py-3 text-[1.2rem] text-white hover:bg-blue-700 focus:outline-none"
              >
                {loading ? (
                  <Loader2 className="mx-5 h-6 w-6 animate-spin" />
                ) : (
                  `${t(TEXT?.SUBMIT)} ${t(TEXT?.REQUEST)}`
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
