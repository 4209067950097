import { toast } from "react-toastify";
import { API_CONSTANT } from "../../../src/services/ApiConstant";
import { getFromAsync, SESSION_KEY } from "../../../src/lib/utils";
import { post, postWithOutToken } from "../../../src/services/ApiService";
import {
  setCountryList,
  setDeliveryAddressList,
  setIsAddressListLoading,
} from "./reducer";

export const fetchCountryList = async (dispatch) => {
  const userData = getFromAsync(SESSION_KEY?.USER_DATA);
  const AppUserId = userData && userData.AppUserId;

  post(API_CONSTANT?.COUNTRY_LIST, { AppUserId: AppUserId })
    .then((res) => {
      if (res?.data?.IsError) {
        toast.error(res?.data?.ErrorMessage || res?.message || String(res));
      } else {
        dispatch(setCountryList(res?.data?.CountryList));
      }
    })
    .catch((err) => {
      toast.error(err?.data?.ErrorMessage || err?.message || String(err));
    });
};

export const fetchDeliveryAddressList = async (dispatch) => {
  const userData = getFromAsync(SESSION_KEY?.USER_DATA);
  const AppUserId = userData && userData.AppUserId;

  post(API_CONSTANT?.DELIVERY_ADDRESS_LISTS, { AppUserId: AppUserId })
    .then((res) => {
      dispatch(setIsAddressListLoading(true));
      if (res?.data?.IsError) {
        toast.error(res?.data?.ErrorMessage || res?.message || String(res));
      } else {
        dispatch(setDeliveryAddressList(res?.data));
      }
    })
    .catch((err) => {
      toast.error(err?.data?.ErrorMessage || err?.message || String(err));
    })
    .finally(() => dispatch(setIsAddressListLoading(false)));
};

export const bookSearchApi = ({
  keyword,
  statsId,
  setBookIdText,
  BookDetailsApi,
  setSearchLoader,
  currentUserData,
  setBookSearchData,
}) => {
  setSearchLoader(true);
  setBookSearchData([]);
  setBookIdText && setBookIdText("");

  const formattedText = keyword.replace(/  +/g, "\n").trim();
  let formattedKeyword = formattedText.replace(/\\n/g, "\n");

  let ISBNList = [];
  let searchText = keyword;

  if (formattedKeyword.includes(",") || formattedKeyword.includes("\n")) {
    ISBNList = formattedKeyword
      .split(/[\n,]+/)
      .map((item) => item.trim())
      .filter((item) => item);
    searchText = "";
  }

  const requestData = {
    IPAddress: "",
    ISBNList,
    StatsTermId: statsId,
    SearchText: searchText,
    AppUserId: currentUserData?.AppUserId,
  };

  postWithOutToken(API_CONSTANT.BOOK_SEARCH, requestData)
    .then((res) => {
      if (res?.data?.IsError) {
        toast.error(res?.data?.ErrorMessage);
      } else {
        setBookSearchData(res?.data?.BookList);
        if (
          setBookIdText &&
          BookDetailsApi &&
          res?.data?.BookList?.length > 0
        ) {
          const firstSku = res?.data?.BookList[0]?.SkuNumber;
          setBookIdText(firstSku);
          BookDetailsApi(firstSku);
        }
      }
      setSearchLoader(false);
    })
    .catch((err) => {
      toast.error(
        err?.data?.ErrorMessage || err?.message || "Something went wrong",
      );
      setSearchLoader(false);
    });
};
