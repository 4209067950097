import { forwardRef, useEffect, useState } from "react";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "./ui/navigation-menu";
import {
  Sheet,
  SheetTitle,
  SheetHeader,
  SheetContent,
  SheetTrigger,
  SheetDescription,
} from "./ui/sheet";
import {
  cn,
  SESSION_KEY,
  getFromAsync,
  navigationMenu,
  removeFromAsync,
  languages,
} from "../lib/utils";
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Logo } from "./ui/icons";
import { Badge } from "./ui/badge";
import ROUTES from "../services/AppConstant";
import { useTranslation } from "react-i18next";
import { TEXT } from "../constant/textConstants";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";
import { setCountryList, setSelectedBook } from "../store/orders/reducer";
import { AlignLeft, LogOut, ShoppingCart, User, Package } from "lucide-react";

const Header = () => {
  const ListItem = forwardRef(
    ({ className, title, children, ...props }, ref) => (
      <li>
        <NavigationMenuLink asChild>
          <a
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className,
            )}
            {...props}
          >
            <div className="text-sm font-medium leading-none">{title}</div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
              {children}
            </p>
          </a>
        </NavigationMenuLink>
      </li>
    ),
  );
  ListItem.displayName = "ListItem";
  let location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transparentHeader = ["/", "/searchApi", "/order"];
  const { bookOrders } = useSelector((state) => state);
  const currentUserData = getFromAsync(SESSION_KEY.USER_DATA);

  const [scrolled, setScrolled] = useState(false);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    JSON.parse(localStorage.getItem("language")) || {
      name: "En",
      code: "en",
      flag: "images/flags/us.png",
    },
  );

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      if (transparentHeader?.includes(pathname)) {
        setScrolled(window.scrollY > 300);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // router.refresh();
    setIsSheetOpen(false);
  }, [pathname]);

  const handleLogout = () => {
    dispatch(setCountryList([]));
    dispatch(setSelectedBook(null));
    removeFromAsync(SESSION_KEY.USER_DATA);
    navigate(ROUTES?.HOME);
  };

  const changeLanguage = (lang) => {
    if (lang?.code === selectedLanguage?.code) return;
    setSelectedLanguage(lang);
    localStorage.setItem("language", JSON.stringify(lang));
    i18n.changeLanguage(lang?.code);
  };

  return (
    <div
      className={`${scrolled || !transparentHeader?.includes(pathname) ? "bg-[rgba(255, 255, 255, 0.7)] shadow-header" : "bg-home-bg"} sticky left-0 top-0 z-50 w-full p-5 backdrop-blur-xl`}
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Logo
              color={
                scrolled || !transparentHeader?.includes(pathname)
                  ? "black"
                  : "white"
              }
            />
          </div>

          <div className="hidden md:block">
            <div className="flex items-center space-x-8">
              <NavigationMenu className="hidden lg:block">
                <NavigationMenuList>
                  {navigationMenu?.map((item, index) => {
                    return (
                      <NavigationMenuItem key={index} className={`px-4`}>
                        <NavigationMenuLink
                          href={item.href}
                          className={
                            (navigationMenuTriggerStyle(),
                            ` ${
                              scrolled || !transparentHeader?.includes(pathname)
                                ? "font-semibold text-dark-gray-1"
                                : "text-white"
                            } bg-transparent text-lg hover:bg-transparent`)
                          }
                        >
                          {t(item.label)}
                        </NavigationMenuLink>
                      </NavigationMenuItem>
                    );
                  })}
                </NavigationMenuList>
              </NavigationMenu>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <a
              href={ROUTES?.CHECK_OUT}
              className={
                (navigationMenuTriggerStyle(),
                `${
                  scrolled || !transparentHeader?.includes(pathname)
                    ? "text-black-1"
                    : "text-white"
                } relative rounded-md py-1 text-lg font-medium`)
              }
            >
              <ShoppingCart className="!h-7 !w-7" />

              {bookOrders?.bookOrders?.length > 0 && (
                <Badge
                  variant="destructive"
                  className={`${bookOrders?.bookOrders?.length < 9 ? "px-2" : "px-1"} absolute -right-2 -top-2 text-xs`}
                >
                  {bookOrders?.bookOrders?.length}
                </Badge>
              )}
            </a>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Avatar
                  className="h-10 w-10 cursor-pointer !bg-transparent"
                  onClick={() => {
                    if (!currentUserData?.IsLoggedIn) {
                      navigate(ROUTES?.LOGIN);
                    }
                  }}
                >
                  <AvatarImage src="/placeholder.svg" alt="User avatar" />
                  <AvatarFallback
                    className={
                      (navigationMenuTriggerStyle(),
                      `${
                        scrolled || !transparentHeader?.includes(pathname)
                          ? "text-black-1"
                          : "text-white"
                      } !bg-transparent`)
                    }
                  >
                    <User className="!h-7 !w-7" />
                  </AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>
              {currentUserData?.IsLoggedIn && (
                <DropdownMenuContent
                  align="end"
                  className="!z-[9999] w-56 !p-3"
                >
                  {currentUserData?.AppUserName && (
                    <div className="flex flex-col p-2">
                      <p className="text-base font-medium capitalize">
                        {currentUserData?.AppUserName}
                      </p>
                    </div>
                  )}
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={() => navigate(ROUTES?.ORDER_LIST)}
                  >
                    <Package className="mr-2 h-4 w-4" />
                    <span className="text-base font-medium">
                      {t(TEXT?.ORDERS)}
                    </span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={handleLogout}
                    className="cursor-pointer"
                  >
                    <LogOut className="mr-2 h-4 w-4" />
                    <span className="text-base font-medium">
                      {t(TEXT?.LOGOUT)}
                    </span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              )}
            </DropdownMenu>

            <div className="relative">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex !w-max items-center space-x-2 rounded bg-white px-1 py-0.5 transition-colors duration-200 hover:bg-purple-3 focus:outline-none focus:ring-2 focus:ring-purple-1"
              >
                <span className="flex items-center gap-1">
                  <img
                    src={selectedLanguage?.flag}
                    alt="flag"
                    width={20}
                    height={20}
                  />{" "}
                  <span>{selectedLanguage?.name}</span>
                </span>
              </button>

              {isOpen && (
                <div
                  className="z-60 absolute mt-1 w-full rounded border bg-white shadow-sm"
                  role="menu"
                  id="language-menu"
                >
                  <div>
                    {languages?.map((language) => (
                      <button
                        key={language?.code}
                        onClick={() => {
                          changeLanguage(language);
                          setIsOpen(false);
                        }}
                        className={`flex w-full items-center justify-center py-2 text-center text-sm ${
                          selectedLanguage?.code === language?.code
                            ? "bg-slate-200"
                            : "hover:bg-slate-100"
                        }`}
                        role="menuitem"
                      >
                        <span className="flex items-center gap-1">
                          <img
                            src={language?.flag}
                            alt="flag"
                            width={20}
                            height={20}
                          />
                          <span>{language?.name}</span>
                        </span>
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <Sheet
              open={isSheetOpen}
              className="!z-[10300] lg:hidden"
              onOpenChange={setIsSheetOpen}
            >
              <SheetTrigger asChild>
                <button
                  className="border-none outline-none lg:hidden"
                  onClick={() => {
                    setIsSheetOpen(true);
                  }}
                >
                  <AlignLeft
                    className={`h-8 w-8 ${
                      scrolled || !transparentHeader?.includes(pathname)
                        ? "text-black-1"
                        : "text-white"
                    }`}
                  />
                </button>
              </SheetTrigger>
              <SheetContent>
                <SheetHeader>
                  <SheetTitle>
                    <Logo />
                  </SheetTitle>
                </SheetHeader>
                <SheetDescription />
                <div className="mt-7 grid gap-4 py-4">
                  <ul className="space-y-2">
                    {navigationMenu?.map((ele, ind) => {
                      return (
                        <a
                          key={ind}
                          href={ele?.href}
                          className="flex items-center space-x-2 py-2"
                        >
                          <div className="text-gray-500">{t(ele?.label)}</div>
                        </a>
                      );
                    })}
                    <a
                      href={ROUTES?.LOGIN}
                      className="flex items-center space-x-2 py-2"
                    >
                      <div className="text-gray-500">
                        {currentUserData?.IsLoggedIn
                          ? `${t(TEXT?.LOGOUT)}`
                          : `${t(TEXT?.LOGIN)}`}
                      </div>
                    </a>
                  </ul>
                </div>
              </SheetContent>
            </Sheet>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
