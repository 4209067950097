const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  ORDER: "/order",
  CHECK_OUT: "/check-out",
  ORDER_LIST: "/order-list",
  SEARCH_API: "/search-api",
  CONTACT_US: "/contact-us",
  BOOK_DETAILS: "/book-details",
};
export default ROUTES;
