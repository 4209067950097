import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { addDays, format } from "date-fns";
import ROUTES from "../services/AppConstant";
import { TEXT } from "../constant/textConstants";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const getFromAsync = (key) => JSON.parse(localStorage.getItem(key));
export const removeFromAsync = async (key) => localStorage.removeItem(key);
export const setInAsyncStorage = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));

export const calculateDateRange = (availabilityDays = 0) => {
  const baseDate = new Date();
  const daysToAdd = Number(availabilityDays) || 0;

  const earliestDate = addDays(baseDate, daysToAdd + 5);
  const latestDate = addDays(baseDate, daysToAdd + 7);

  const formattedEarliestDate = format(earliestDate, "EEE MMM d");
  const formattedLatestDate = format(latestDate, "EEE MMM d");

  return { formattedEarliestDate, formattedLatestDate };
};

export const SESSION_KEY = {
  USER_DATA: "USER_INFO",
};

export const FEATURES_ARRAY = [
  {
    title: TEXT?.CATALOG_UPDATE,
    description: TEXT?.CATALOG_DETAILS,
    icon: "/icons/home/Shape4.svg",
  },
  {
    title: TEXT?.CUSTOMER_EXPERIENCE,
    description: TEXT?.CUSTOMER_EXPERIENCE_DETAILS,
    icon: "/icons/home/Shape.svg",
  },
  {
    title: TEXT?.SINGLE_PRICE,
    description: TEXT?.SINGLE_PRICE_DETAILS,
    icon: "/icons/home/Shape2.svg",
  },
  {
    title: TEXT?.WE_DELIVER,
    description: TEXT?.WE_DELIVER_DETAILS,
    icon: "/icons/home/Shape3.svg",
  },
  {
    title: TEXT?.SAVE_TIME_COSTS,
    description: TEXT?.SAVE_TIME_COSTS_DETAILS,
    icon: "/icons/home/Shape.svg",
  },
  {
    title: TEXT?.SEARCH_AND_ORDER_API,
    description: TEXT?.SEARCH_AND_ORDER_API_DETAILS,
    icon: "/icons/home/Shape4.svg",
  },
];

export const QUESTION_ARRY = [
  {
    count: 1,
    question: TEXT?.QUESTION_1,
    answer: TEXT?.ANSWER_1,
  },
  {
    count: 2,
    question: TEXT?.QUESTION_2,
    answer: TEXT?.ANSWER_2,
  },
  {
    count: 3,
    question: TEXT?.QUESTION_3,
    answer: TEXT?.ANSWER_3,
  },
  {
    count: 4,
    question: TEXT?.QUESTION_4,
    answer: TEXT?.ANSWER_4,
  },

  {
    count: 5,
    question: TEXT?.QUESTION_5,
    answer: TEXT?.ANSWER_5,
  },

  {
    count: 6,
    question: TEXT?.QUESTION_6,
    answer: TEXT?.ANSWER_6,
  },
  {
    count: 7,
    question: TEXT?.QUESTION_7,
    answer: TEXT?.ANSWER_7,
  },
  {
    count: 8,
    question: TEXT?.QUESTION_8,
    answer: TEXT?.ANSWER_8,
  },
  {
    count: 9,
    question: TEXT?.QUESTION_9,
    answer: TEXT?.ANSWER_9,
  },
  {
    count: 10,
    question: TEXT?.QUESTION_10,
    answer: TEXT?.ANSWER_10,
  },
];

export const ABOUTUS_ARRAY = [
  {
    icon: "/icons/home/Shape4.svg",
    title: TEXT?.SUPPLY_CHAIN_REACH,
    description: TEXT?.SUPPLY_CHAIN_REACH_DETAILS,
  },
  {
    icon: "/icons/home/Shape.svg",
    title: TEXT?.PRACTICES,
    description: TEXT?.PRACTICES_DETAILS,
  },
  {
    icon: "/icons/home/Shape2.svg",
    title: TEXT?.TRY_US,
    description: TEXT?.TRY_US_DETAILS,
  },
  {
    icon: "/icons/home/Shape3.svg",
    title: TEXT?.NO_METADATA,
    description: TEXT?.NO_METADATA_DETAILS,
  },
];

export const navigationMenu = [
  {
    label: TEXT?.HOME,
    href: ROUTES?.HOME,
    className: "font-semibold text-dark-gray-1",
  },
  {
    label: TEXT?.FEATURES,
    href: "/#features",
    className: "hover:bg-transparent hover:underline",
    activeClassName: "bg-custom-gradient bg-clip-text !text-transparent",
  },
  {
    label: TEXT?.ABOUT_US,
    href: "/#about",
    className: "hover:bg-transparent hover:underline",
  },
  {
    label: TEXT?.QA,
    href: "/#question-and-answer",
    className: "hover:bg-transparent hover:underline",
  },
  {
    label: TEXT?.API,
    href: ROUTES.SEARCH_API,
    className: "hover:bg-transparent hover:underline",
  },
  {
    label: TEXT?.ORDER,
    href: ROUTES.ORDER,
    className: "hover:bg-transparent hover:underline",
  },
  {
    label: TEXT?.CONTACT_US,
    href: ROUTES?.CONTACT_US,
    className: "hover:bg-transparent hover:underline",
  },
];

export const languages = [
  { name: "En", code: "en", flag: "images/flags/us.png" },
  { name: "De", code: "de", flag: "images/flags/de.png" },
];
