import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import { Input } from "../components/ui/input";
import JSONInput from "react-json-editor-ajrm";
import { useTranslation } from "react-i18next";
import { TEXT } from "../constant/textConstants";
import locale from "react-json-editor-ajrm/locale/en";
import LottieSpinner from "../components/LottySpinner";
import { API_CONSTANT } from "../services/ApiConstant";
import { bookSearchApi } from "../store/orders/action";
import { ChevronRight, SearchIcon } from "lucide-react";
import { SESSION_KEY, getFromAsync } from "../lib/utils";
import { postWithOutToken } from "../services/ApiService";

export default function SearchApi() {
  const userData = getFromAsync(SESSION_KEY?.USER_DATA);
  const AppUserId = userData && userData.AppUserId;

  const [statesList, setStatesList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [bookIdText, setBookIdText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [detailsData, setDetailsData] = useState({});
  const [searchLoader, setSearchLoader] = useState(false);
  const [bookSearchData, setBookSearchData] = useState([]);
  const [detailsLoader, setDetailsLoader] = useState(false);

  const { t } = useTranslation();

  const handlePasteValue = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData
      .getData("text")
      .replace(/\r\n|\r|\n/g, "   ");
    const currentValue = searchText;
    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;

    if (selectionStart === 0 && selectionEnd === currentValue.length) {
      setSearchText(pastedText);
    } else {
      const newValue =
        currentValue.slice(0, selectionStart) +
        pastedText +
        currentValue.slice(selectionEnd);
      setSearchText(newValue);
    }
  };

  const BookDetailsApi = (SkuNumber) => {
    setDetailsLoader(true);
    const obj = {
      BookId: 0,
      IPAddress: "",
      SkuNumber: SkuNumber,
      AppUserId: AppUserId,
    };
    postWithOutToken(`${API_CONSTANT?.BOOK_DETAILS}`, obj)
      .then((res) => {
        setDetailsLoader(false);
        setDetailsData(res?.data);
        if (res?.data?.IsError === true) {
          toast.error(res?.data?.ErrorMessage);
        }
      })
      .catch((err) => {
        setDetailsLoader(false);
      });
  };

  const handleSearch = () => {
    bookSearchApi({
      keyword: searchText,
      statsId: "",
      setBookSearchData,
      setSearchLoader,
      setBookIdText,
      BookDetailsApi,
      currentUserData: { AppUserId },
    });
  };

  const getStatesGroupApi = () => {
    setIsLoading(true);
    const obj = {
      AppUserId: AppUserId,
    };
    postWithOutToken(`${API_CONSTANT?.STATS_GROUP}`, obj)
      .then((res) => {
        setIsLoading(false);
        setStatesList(res?.data?.GroupStatsList);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getStatesGroupApi();
  }, []);

  return (
    <div>
      <div className="mb-[30px] h-full w-full bg-home-bg pb-[50px] pt-10">
        <div className="m-auto max-w-7xl px-[15px]">
          <div className="flex justify-center">
            <div className="w-full md:max-w-[668px]">
              <h1 className="text-center text-2xl font-semibold text-white lg:text-[34px] lg:leading-[40px]">
                {t(TEXT?.SEARCH_API)} - {t(TEXT?.GET_JSON_RESULTS)}
              </h1>
              <div className="flex flex-wrap justify-center py-[20px] sm:justify-between">
                <div className="flex items-baseline">
                  <p className="mb-[5px] flex items-center justify-center pr-[5px] text-3xl font-medium text-white lg:text-4xl">
                    468235845
                  </p>
                  <p className="text-[1rem] text-white">
                    {t(TEXT?.TOTAL_RECORDS)}
                  </p>
                </div>
                <div className="flex items-baseline">
                  <p className="mb-[5px] flex items-center justify-center pr-[5px] text-3xl font-medium text-white lg:text-4xl">
                    16992546
                  </p>
                  <p className="text-[1rem] text-white">
                    {t(TEXT?.AVAILABLE_BOOKS)}
                  </p>
                </div>
              </div>
              <div className="py-5">
                <div className="relative flex h-[50px] items-center justify-center rounded-[6px] bg-white shadow-Searchbar md:h-[63px]">
                  <SearchIcon className="mx-2 size-7 text-gray-500" />
                  <Input
                    autoFocus={true}
                    className="border-none bg-transparent pl-2 pr-16 shadow-none focus:outline-none focus-visible:ring-0"
                    placeholder={t(TEXT?.BUSINESS)}
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e?.target?.value);
                    }}
                    onKeyDownCapture={(e) =>
                      e.key === "Enter" && handleSearch()
                    }
                    onPaste={(e) => handlePasteValue(e)}
                  />
                  <span
                    className="mx-2 cursor-pointer pr-1 text-base text-blue-400"
                    onClick={() => handleSearch()}
                  >
                    {t(TEXT?.SEARCH)}
                  </span>
                </div>

                {searchLoader ? (
                  <Spinner spinnerClass="top-20" />
                ) : (
                  searchText &&
                  bookSearchData?.length !== 0 && (
                    <div className="pt-5 text-left">
                      <JSONInput
                        id={1}
                        width="100%"
                        height="550px"
                        locale={locale}
                        confirmGood={false}
                        theme="dark_vscode_tribute"
                        placeholder={bookSearchData}
                        colors={{
                          keys: "#4fdee5",
                          string: "#0ad161",
                          number: "#0ad161",
                          primitive: "#ff8c00",
                        }}
                        style={{
                          contentBox: {
                            fontSize: "14px",
                            fontFamily:
                              "Menlo,Monaco,Consolas,Courier New,monospace",
                          },
                        }}
                      />

                      <div className="mt-10">
                        <h1 className="text-center text-2xl font-semibold text-white lg:text-[28px] lg:leading-[40px]">
                          {t(TEXT?.CONTINUE_WITH_BOOK_SKU)} -{" "}
                          {t(TEXT?.GET_JSON_RESULTS)}
                        </h1>
                      </div>
                      <div className="relative mt-4 flex h-[50px] items-center justify-center rounded-[6px] bg-white shadow-Searchbar md:h-[63px]">
                        <SearchIcon className="mx-2 size-7 text-gray-500" />
                        <Input
                          value={bookIdText}
                          placeholder="Enter SKU"
                          onChange={(e) => setBookIdText(e?.target?.value)}
                          className="border-none bg-transparent pl-2 pr-16 shadow-none focus:outline-none focus-visible:ring-0"
                          onKeyDownCapture={(e) =>
                            e.key === "Enter" && BookDetailsApi(bookIdText)
                          }
                        />
                        <span
                          onClick={() => BookDetailsApi(bookIdText)}
                          className="mx-2 cursor-pointer pr-1 text-base text-blue-400"
                        >
                          {t(TEXT?.CONTINUE)}
                        </span>
                      </div>
                      <>
                        {detailsLoader ? (
                          <Spinner spinnerClass="top-20" />
                        ) : (
                          bookIdText &&
                          detailsData?.Book && (
                            <div className="pt-5 text-left">
                              <JSONInput
                                id={2}
                                placeholder={detailsData?.Book}
                                theme="dark_vscode_tribute"
                                locale={locale}
                                height="550px"
                                width="100%"
                                confirmGood={false}
                                colors={{
                                  keys: "#4fdee5",
                                  string: "#0ad161",
                                  primitive: "#ff8c00",
                                  number: "#0ad161",
                                }}
                                style={{
                                  contentBox: {
                                    fontSize: "14px",
                                    fontFamily:
                                      "Menlo,Monaco,Consolas,Courier New,monospace",
                                  },
                                }}
                              />
                            </div>
                          )
                        )}
                      </>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-auto max-w-7xl">
        {isLoading ? (
          <LottieSpinner />
        ) : (
          statesList?.map((list, i) => {
            return (
              <>
                {list?.StatsTermsList?.length !== 0 && (
                  <div className="w-full p-[30px]" key={i}>
                    <div className="mt-[20px]">
                      <p className="border-t border-light-gray-2 pt-[15px] text-[24px] text-dark-gray-1">
                        {list?.GroupName}
                        <span className="pl-[10px] text-light-gray-2">
                          {list?.GroupDesc}
                        </span>
                      </p>
                    </div>
                    <div className="mx-[calc(-.5*1.5rem)] mt-[10px] flex flex-wrap justify-start">
                      {list?.StatsTermsList?.map((el, i) => {
                        return (
                          <div
                            className="w-full cursor-pointer px-[calc(1.5rem*.5)] md:w-[50%] lg:w-[25%]"
                            key={i}
                            onClick={() => {
                              setSearchText(el?.SearchTerm);
                              bookSearchApi(el?.SearchTerm, el?.StatsTermId);
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                            }}
                          >
                            <div className="my-[10px] flex h-[47px] items-center justify-between rounded-[6px] border border-light-gray-3 p-[8px] text-base shadow-Search-card hover:shadow-[0_3px_10px_rgb(0,0,0,0.2)]">
                              <p>{el?.SearchTerm}</p>
                              <div className="flex items-center">
                                <p className="min-w-[70px] text-end text-purple-2">
                                  {el?.Records}
                                </p>
                                <ChevronRight className="size-5 text-purple-2" />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            );
          })
        )}
      </div>
    </div>
  );
}
