export const S_TEXT = {
  BY: "By",
  QTY: "Qty",
  USD: "USD",
  SKU: "SKU",
  LOADING: "Loading",
  ISBN_13: "ISBN-13",
  SUPPORT_EMAIL: "support@spondees.com",
};

export const TEXT = {
  QA: "QA",
  BY: "BY",
  NOW: "NOW",
  ADD: "ADD",
  AND: "AND",
  API: "API",
  SKU: "SKU",
  NAME: "NAME",
  LIST: "LIST",
  ITEM: "ITEM",
  EDIT: "EDIT",
  YOUR: "YOUR",
  CITY: "CITY",
  SAVE: "SAVE",
  BACK: "BACK",
  HOME: "HOME",
  COPI: "COPI",
  DAYS: "DAYS",
  LOGIN: "LOGIN",
  TOTAL: "TOTAL",
  PAGES: "PAGES",
  ITEMS: "ITEMS",
  PRICE: "PRICE",
  ENTER: "ENTER",
  STATE: "STATE",
  TAXES: "TAXES",
  ORDER: "ORDER",
  COPIS: "COPIS",
  TRY_US: "TRY_US",
  SUBMIT: "SUBMIT",
  SEARCH: "SEARCH",
  WEIGHT: "WEIGHT",
  CHANGE: "CHANGE",
  STATUS: "STATUS",
  SELECT: "SELECT",
  CANCEL: "CANCEL",
  ORDERS: "ORDERS",
  AMOUNT: "AMOUNT",
  LOGOUT: "LOGOUT",
  NUMBER: "NUMBER",
  ISBN_10: "ISBN_10",
  ISBN_13: "ISBN_13",
  JOIN_US: "JOIN_US",
  REQUEST: "REQUEST",
  BINDING: "BINDING",
  COUNTRY: "COUNTRY",
  DETAILS: "DETAILS",
  CONTACT: "CONTACT",
  SHIPS_IN: "SHIPS_IN",
  ABOUT_US: "ABOUT_US",
  ANSWER_1: "ANSWER_1",
  ANSWER_2: "ANSWER_2",
  ANSWER_3: "ANSWER_3",
  ANSWER_4: "ANSWER_4",
  ANSWER_5: "ANSWER_5",
  ANSWER_6: "ANSWER_6",
  ANSWER_7: "ANSWER_7",
  ANSWER_8: "ANSWER_8",
  ANSWER_9: "ANSWER_9",
  USERNAME: "USERNAME",
  PASSWORD: "PASSWORD",
  CONTINUE: "CONTINUE",
  BUSINESS: "BUSINESS",
  LANGUAGE: "LANGUAGE",
  CHECKOUT: "CHECKOUT",
  SHOP_NOW: "SHOP_NOW",
  VIEW_ALL: "VIEW_ALL",
  PIN_CODE: "PIN_CODE",
  OPTIONAL: "OPTIONAL",
  FEATURES: "FEATURES",
  AVAILABLE: "AVAILABLE",
  PUBLISHER: "PUBLISHER",
  ORDER_API: "ORDER_API",
  NO_HASSLE: "NO_HASSLE",
  PRACTICES: "PRACTICES",
  ANSWER_10: "ANSWER_10",
  FOR_ORDER: "FOR_ORDER",
  SHOW_LESS: "SHOW_LESS",
  ADDRESSES: "ADDRESSES",
  RECIPIENT: "RECIPIENT",
  PLACED_ON: "PLACED_ON",
  COPYRIGHT: "COPYRIGHT",
  SEARCH_API: "SEARCH_API",
  WE_DELIVER: "WE_DELIVER",
  CONTACT_US: "CONTACT_US",
  QUESTION_1: "QUESTION_1",
  QUESTION_2: "QUESTION_2",
  QUESTION_3: "QUESTION_3",
  QUESTION_4: "QUESTION_4",
  QUESTION_5: "QUESTION_5",
  QUESTION_6: "QUESTION_6",
  QUESTION_7: "QUESTION_7",
  QUESTION_8: "QUESTION_8",
  QUESTION_9: "QUESTION_9",
  CART_EMPTY: "CART_EMPTY",
  GET_STARTED: "GET_STARTED",
  NO_METADATA: "NO_METADATA",
  QUESTION_10: "QUESTION_10",
  ADD_TO_CART: "ADD_TO_CART",
  DELIVERY_BY: "DELIVERY_BY",
  PLACE_ORDER: "PLACE_ORDER",
  SINGLE_PRICE: "SINGLE_PRICE",
  HOW_IT_WORKS: "HOW_IT_WORKS",
  SUBMIT_LABEL: "SUBMIT_LABEL",
  GET_IN_TOUCH: "GET_IN_TOUCH",
  PHONE_NUMBER: "PHONE_NUMBER",
  BOOK_DETAILS: "BOOK_DETAILS",
  CREATE_ORDER: "CREATE_ORDER",
  CART_WAITING: "CART_WAITING",
  DELIVER_HERE: "DELIVER_HERE",
  ADDRESS_LINE: "ADDRESS_LINE",
  BOOKS_SHIPPED: "BOOKS_SHIPPED",
  GET_MORE_DONE: "GET_MORE_DONE",
  ANY_QUESTIONS: "ANY_QUESTIONS",
  EMAIL_ADDRESS: "EMAIL_ADDRESS",
  TOTAL_RECORDS: "TOTAL_RECORDS",
  ORDER_SUMMARY: "ORDER_SUMMARY",
  TOTAL_PAYABLE: "TOTAL_PAYABLE",
  CATALOG_UPDATE: "CATALOG_UPDATE",
  TRY_US_DETAILS: "TRY_US_DETAILS",
  FILL_OUT_FIELD: "FILL_OUT_FIELD",
  BOOK_NOT_FOUND: "BOOK_NOT_FOUND",
  LARGE_SELECTION: "LARGE_SELECTION",
  CATALOG_DETAILS: "CATALOG_DETAILS",
  SAVE_TIME_COSTS: "SAVE_TIME_COSTS",
  ENTER_REAL_NAME: "ENTER_REAL_NAME",
  AVAILABLE_BOOKS: "AVAILABLE_BOOKS",
  SIGN_IN_MESSAGE: "SIGN_IN_MESSAGE",
  ADD_NEW_ADDRESS: "ADD_NEW_ADDRESS",
  ATTACH_DOCUMENT: "ATTACH_DOCUMENT",
  NO_ORDERS_FOUND: "NO_ORDERS_FOUND",
  CONNECT_WITH_US: "CONNECT_WITH_US",
  OPERATION_GLOBAL: "OPERATION_GLOBAL",
  PREPACKED_ORDERS: "PREPACKED_ORDERS",
  MARKET_EXPANSION: "MARKET_EXPANSION",
  DISCUSS_SPONDEES: "DISCUSS_SPONDEES",
  GET_JSON_RESULTS: "GET_JSON_RESULTS",
  COPIES_AVAILABLE: "COPIES_AVAILABLE",
  PUBLICATION_DATE: "PUBLICATION_DATE",
  VIEW_PAST_ORDERS: "VIEW_PAST_ORDERS",
  DELIVERY_ADDRESS: "DELIVERY_ADDRESS",
  REFERENCE_NUMBER: "REFERENCE_NUMBER",
  DELIVERY_CHARGES: "DELIVERY_CHARGES",
  SYSTEM_THAT_WORKS: "SYSTEM_THAT_WORKS",
  CONNECT_ECOMMERCE: "CONNECT_ECOMMERCE",
  BOOKSELLING_SETUP: "BOOKSELLING_SETUP",
  PRACTICES_DETAILS: "PRACTICES_DETAILS",
  QUESTIONS_ANSWERS: "QUESTIONS_ANSWERS",
  COUNTRY_OF_ORIGIN: "COUNTRY_OF_ORIGIN",
  WE_DELIVER_DETAILS: "WE_DELIVER_DETAILS",
  ORGANIZED_SOURCING: "ORGANIZED_SOURCING",
  SUPPLY_CHAIN_REACH: "SUPPLY_CHAIN_REACH",
  ENTER_PHONE_NUMBER: "ENTER_PHONE_NUMBER",
  SOURCE_BOOKS_GLOBAL: "SOURCE_BOOKS_GLOBAL",
  CUSTOMER_EXPERIENCE: "CUSTOMER_EXPERIENCE",
  INVITE_ONLY_SERVICE: "INVITE_ONLY_SERVICE",
  INITIATE_SEARCH_API: "INITIATE_SEARCH_API",
  NO_METADATA_DETAILS: "NO_METADATA_DETAILS",
  RETURN_TO_BOOK_LIST: "RETURN_TO_BOOK_LIST",
  SINGLE_PRICE_DETAILS: "SINGLE_PRICE_DETAILS",
  SEARCH_AND_ORDER_API: "SEARCH_AND_ORDER_API",
  AUSTRIA_HOME_COUNTRY: "AUSTRIA_HOME_COUNTRY",
  INTERESTED_LETS_TALK: "INTERESTED_LETS_TALK",
  CHECK_EMAIL_ACCURACY: "CHECK_EMAIL_ACCURACY",
  PLACE_OF_PUBLICATION: "PLACE_OF_PUBLICATION",
  NO_ORDER_INFORMATION: "NO_ORDER_INFORMATION",
  SOURCE_FOREIGN_BOOKS: "SOURCE_FOREIGN_BOOKS",
  ORDER_PLACED_SUCCESS: "ORDER_PLACED_SUCCESS",
  SHORTER_DELIVERY_TIME: "SHORTER_DELIVERY_TIME",
  ENDLESS_POSSIBILITIES: "ENDLESS_POSSIBILITIES",
  BOOKS_PACKED_DELIVERY: "BOOKS_PACKED_DELIVERY",
  CONTINUE_WITH_BOOK_SKU: "CONTINUE_WITH_BOOK_SKU",
  BOOK_NOT_FOUND_MESSAGE: "BOOK_NOT_FOUND_MESSAGE",
  NO_ORDER_FOUND_MESSAGE: "NO_ORDER_FOUND_MESSAGE",
  SAVE_TIME_COSTS_DETAILS: "SAVE_TIME_COSTS_DETAILS",
  FAMILY_BUSINESS_HISTORY: "FAMILY_BUSINESS_HISTORY",
  ENTER_USERNAME_PASSWORD: "ENTER_USERNAME_PASSWORD",
  FOREIGN_BOOKS_TO_AUSTRIA: "FOREIGN_BOOKS_TO_AUSTRIA",
  FOREIGN_BOOKS_DEFINITION: "FOREIGN_BOOKS_DEFINITION",
  CHECK_QUESTIONS_ANSWERED: "CHECK_QUESTIONS_ANSWERED",
  EXPLAIN_QUESTION_DETAILS: "EXPLAIN_QUESTION_DETAILS",
  CONTINUE_TO_CONFIRM_ORDER: "CONTINUE_TO_CONFIRM_ORDER",
  SUPPLY_CHAIN_REACH_DETAILS: "SUPPLY_CHAIN_REACH_DETAILS",
  GLOBAL_E_COMMERCE_EXPANSION: "GLOBAL_E_COMMERCE_EXPANSION",
  CUSTOMER_EXPERIENCE_DETAILS: "CUSTOMER_EXPERIENCE_DETAILS",
  OUR_ECOMMERCE_STORE_NAME_IS: "OUR_ECOMMERCE_STORE_NAME_IS",
  GLOBAL_BOOK_SOURCING_SERVICE: "GLOBAL_BOOK_SOURCING_SERVICE",
  SEARCH_AND_ORDER_API_DETAILS: "SEARCH_AND_ORDER_API_DETAILS",
  INTERNATIONAL_PRIORITY_DELIVERY: "INTERNATIONAL_PRIORITY_DELIVERY",
};
