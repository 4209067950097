import React, { useEffect, useState } from "react";
import { Card } from "../ui/card";
import EmptyCart from "./EmptyCart";
import { toast } from "react-toastify";
import OrderDetails from "./OrderDetails";
import { useTranslation } from "react-i18next";
import { post } from "../../../src/services/ApiService";
import { S_TEXT, TEXT } from "../../constant/textConstants";
import { ArrowLeft, Loader2, LoaderCircle } from "lucide-react";
import { API_CONSTANT } from "../../../src/services/ApiConstant";
import { getFromAsync, SESSION_KEY } from "../../../src/lib/utils";

const ConfirmOrderList = () => {
  const currentUserData = getFromAsync(SESSION_KEY?.USER_DATA);

  const { t } = useTranslation();

  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);

  useEffect(() => {
    fetchOrderList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOrderList = () => {
    setIsLoading(true);

    post(API_CONSTANT?.CONFIRM_ORDER_LIST, {
      AppUserId: currentUserData?.AppUserId,
    })
      .then((res) => {
        setOrderList(res?.data?.OrderList);
      })
      .catch((err) => {
        toast.error(err?.data?.ErrorMessage || err?.message || String(err));
      })
      .finally(() => setIsLoading(false));
  };

  const getOrderDetails = (order) => {
    setIsDetailsLoading(true);
    const userData = getFromAsync(SESSION_KEY?.USER_DATA);
    const AppUserId = userData && userData.AppUserId;

    post(API_CONSTANT?.ORDER_DETAIL, { AppUserId, OrderId: order?.APIOrderId })
      .then((res) => {
        if (res?.data?.IsError) {
          toast.error(res?.data?.ErrorMessage || res?.message || String(res));
        } else {
          setOrderDetails(res?.data?.Order);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.ErrorMessage || err?.message || String(err));
      })
      .finally(() => {
        setIsDetailsLoading(false);
      });
  };

  return (
    <div
      className={`${isLoading ? "h-[367px]" : "h-auto"} container mx-auto max-w-7xl`}
    >
      {isLoading ? (
        <div className={`${isLoading ? "h-[340px]" : "h-auto"} pt-[20px]`}>
          <div className="flex items-center justify-center">
            <LoaderCircle className="h-9 w-9 animate-spin" />
          </div>
        </div>
      ) : orderList?.length !== 0 ? (
        <div className="space-y-6 py-4">
          {orderDetails ? (
            <div>
              <div
                onClick={() => setOrderDetails(null)}
                className="mb-[2px] flex cursor-pointer items-center gap-1"
              >
                <ArrowLeft className="h-5 w-5" />
                <p>{t(TEXT?.BACK)}</p>
              </div>
              <OrderDetails order={orderDetails} />
            </div>
          ) : (
            <>
              <div className="flex flex-col items-start gap-4">
                <h1 className="text-2xl font-bold">
                  {t(TEXT?.YOUR)} {t(TEXT?.ORDERS)}
                </h1>
              </div>
              <div className="grid gap-4">
                {orderList?.map((order) => (
                  <Card
                    key={order?.APIOrderId}
                    onClick={() => getOrderDetails(order)}
                    className="cursor-pointer overflow-hidden"
                  >
                    <div className="flex items-start gap-4 p-4">
                      <div className="flex-1 space-y-1">
                        <div className="flex items-start justify-between">
                          <div>
                            <h3 className="font-semibold">
                              {t(TEXT?.ORDER)} #{order.OrderNumber.trim()}
                            </h3>
                            <p className="text-sm text-muted-foreground">
                              {t(TEXT?.RECIPIENT)}: {order.RecipientName}
                            </p>
                          </div>
                        </div>
                        <div className="text-sm">
                          <p>{order.ShipAddressLine1}</p>
                          {order.ShipAddressLine2 && (
                            <p>{order.ShipAddressLine2}</p>
                          )}
                          <p>{`${order.ShipCityName}, ${order.ShipCountryName} ${order.ShipPinCode}`}</p>
                        </div>
                        <div className="flex items-center justify-between pt-2">
                          <p className="text-sm text-muted-foreground">
                            {t(TEXT?.CONTACT)}: {order.ShipPhoneNo}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
                {orderList?.length === 0 && (
                  <p className="text-center text-muted-foreground">
                    {t(TEXT?.NO_ORDERS_FOUND)}
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <EmptyCart />
      )}

      {isDetailsLoading && (
        <div className="bg-black fixed inset-0 z-50 flex items-center justify-center bg-opacity-50 backdrop-blur-sm">
          <div className="flex items-center space-x-4 rounded-lg bg-white p-5">
            <Loader2 className="h-8 w-8 animate-spin text-black-1" />
            <p className="text-lg font-semibold text-gray-700">
              {S_TEXT?.LOADING}...
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmOrderList;
