import React, { useEffect } from "react";
import {
  Select,
  SelectValue,
  SelectContent,
  SelectTrigger,
} from "../ui/select";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { LoaderCircle } from "lucide-react";
import { useTranslation } from "react-i18next";
import { TEXT } from "../../constant/textConstants";

const DeliveryAddressForm = ({
  shipCity,
  shipState,
  isLoading,
  bookOrders,
  shipPhoneNo,
  shipPinCode,
  setShipCity,
  addressLine1,
  addressLine2,
  addressLine3,
  setShipState,
  emailAddress,
  recipientName,
  setShipPhoneNo,
  setShipPinCode,
  selectedStatus,
  setAddressLine1,
  setAddressLine2,
  setAddressLine3,
  selectedAddress,
  setEmailAddress,
  setRecipientName,
  addUpdateAddress,
  setEditingAddress,
  setSelectedStatus,
  setSelectedAddress,
  handleSelectCountry,
  setAddingNewAddress,
  selectedShipConutry,
  showConformationMail,
  setSelectedShipConutry,
  setShowConformationMail,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (selectedAddress) {
      setShipCity(selectedAddress?.ShipCityName || "");
      setShipState(selectedAddress?.ShipStateName || "");
      setShipPhoneNo(selectedAddress?.ShipPhoneNo || "");
      setShipPinCode(selectedAddress?.ShipPinCode || "");
      setEmailAddress(selectedAddress?.EmailAddress || "");
      setRecipientName(selectedAddress?.RecipientName || "");
      setAddressLine1(selectedAddress?.ShipAddressLine1 || "");
      setAddressLine2(selectedAddress?.ShipAddressLine2 || "");
      setAddressLine3(selectedAddress?.ShipAddressLine3 || "");

      const status = bookOrders?.deliveryAddressList?.StatusList?.find(
        (ele) => ele?.StatusId === selectedAddress?.StatusId,
      );
      setSelectedStatus(status || null);

      const country = bookOrders?.countryList?.find(
        (ele) => ele.CountryId === selectedAddress?.ShipCountryId,
      );
      setSelectedShipConutry(country || null);
    } else {
      setShipCity("");
      setShipState("");
      setShipPhoneNo("");
      setShipPinCode("");
      setEmailAddress("");
      setRecipientName("");
      setAddressLine1("");
      setAddressLine2("");
      setAddressLine3("");
      setSelectedStatus("");
      setSelectedShipConutry("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress, bookOrders]);

  const handleSelectStatus = (selectedValue) => {
    const selectedStatus = bookOrders?.deliveryAddressList?.StatusList?.find(
      (item) => item.StatusId.toString() === selectedValue,
    );
    setSelectedStatus(selectedStatus || null);
  };

  return (
    <div className="space-y-2">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="recipientName">
            {t(TEXT?.RECIPIENT)} {t(TEXT?.NAME)}
          </label>
          <Input
            id="recipientName"
            value={recipientName}
            placeholder={`${t(TEXT?.ENTER)} ${t(TEXT?.RECIPIENT)} ${t(TEXT?.NAME)}`}
            onChange={(e) => setRecipientName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="phoneNumber">{t(TEXT?.PHONE_NUMBER)}</label>
          <Input
            id="phoneNumber"
            value={shipPhoneNo}
            placeholder={`${t(TEXT?.ENTER)} ${t(TEXT?.PHONE_NUMBER)}`}
            onChange={(e) => setShipPhoneNo(e.target.value)}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="emailAddress">{t(TEXT?.EMAIL_ADDRESS)}</label>
          <Input
            id="emailAddress"
            value={emailAddress}
            placeholder={`${t(TEXT?.ENTER)} ${t(TEXT?.EMAIL_ADDRESS)}`}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="status">{t(TEXT?.STATUS)}</label>
          <Select
            required
            value={
              selectedStatus?.StatusId !== undefined
                ? selectedStatus.StatusId
                : ""
            }
            onValueChange={(value) => handleSelectStatus(value)}
          >
            <SelectTrigger id="status">
              <SelectValue
                placeholder={`${t(TEXT?.SELECT)} ${t(TEXT?.STATUS)}`}
              >
                {selectedStatus?.StatusText ||
                  `${t(TEXT?.SELECT)} ${t(TEXT?.STATUS)}`}
              </SelectValue>
            </SelectTrigger>
            <SelectContent
              items={bookOrders?.deliveryAddressList?.StatusList?.map(
                (item) => ({
                  label: item.StatusText || "Unknown",
                  value: item.StatusId.toString(),
                }),
              )}
            />
          </Select>
        </div>
      </div>

      <div>
        <label htmlFor="addressLine1">{t(TEXT?.ADDRESS_LINE)} 1</label>
        <Input
          id="addressLine1"
          value={addressLine1}
          placeholder={`${t(TEXT?.ENTER)} ${t(TEXT?.YOUR)} ${t(TEXT?.ADDRESS_LINE)} 1`}
          onChange={(e) => setAddressLine1(e.target.value)}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="addressLine2">{t(TEXT?.ADDRESS_LINE)} 2</label>
          <Input
            id="addressLine2"
            value={addressLine2}
            placeholder={`${t(TEXT?.ENTER)} ${t(TEXT?.YOUR)} ${t(TEXT?.ADDRESS_LINE)} 2`}
            onChange={(e) => setAddressLine2(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="addressLine3">{t(TEXT?.ADDRESS_LINE)} 3</label>
          <Input
            id="addressLine3"
            value={addressLine3}
            placeholder={`${t(TEXT?.ENTER)} ${t(TEXT?.YOUR)} ${t(TEXT?.ADDRESS_LINE)} 3`}
            onChange={(e) => setAddressLine3(e.target.value)}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="pinCode">{t(TEXT?.PIN_CODE)}</label>
          <Input
            id="pinCode"
            value={shipPinCode}
            placeholder={`${t(TEXT?.ENTER)} ${t(TEXT?.PIN_CODE)}`}
            onChange={(e) => setShipPinCode(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="shipCountry">{t(TEXT?.COUNTRY)}</label>
          <Select
            required
            value={selectedShipConutry?.CountryId}
            onValueChange={(value) =>
              handleSelectCountry(
                bookOrders?.countryList.find(
                  (item) => item.CountryId === value,
                ),
              )
            }
          >
            <SelectTrigger id="shipCountry">
              <SelectValue
                placeholder={`${t(TEXT?.SELECT)} ${t(TEXT?.COUNTRY)}`}
              >
                {selectedShipConutry?.CountryName ||
                  `${t(TEXT?.SELECT)} ${t(TEXT?.COUNTRY)}`}
              </SelectValue>
            </SelectTrigger>
            <SelectContent
              items={bookOrders?.countryList?.map((item) => ({
                label: item.CountryName || "Unknown",
                value: item.CountryId || "unknown",
              }))}
            />
          </Select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="shipCity">{t(TEXT?.CITY)}</label>
          <Input
            id="shipCity"
            value={shipCity}
            placeholder={`${t(TEXT?.ENTER)} ${t(TEXT?.CITY)} ${t(TEXT?.NAME)}`}
            onChange={(e) => setShipCity(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="shipStateName">{t(TEXT?.STATE)}</label>
          <Input
            id="shipStateName"
            value={shipState}
            placeholder={`${t(TEXT?.ENTER)} ${t(TEXT?.STATE)} ${t(TEXT?.NAME)}`}
            onChange={(e) => setShipState(e.target.value)}
          />
        </div>
      </div>

      <div className="flex gap-4">
        <Button
          disabled={isLoading}
          className="bg-purple-1 text-white hover:bg-gray-200 hover:font-medium hover:text-purple-1"
          onClick={() => {
            addUpdateAddress(selectedAddress);
            setShowConformationMail(true);
          }}
        >
          {isLoading ? (
            <div className="animate-spin">
              <LoaderCircle />
            </div>
          ) : (
            <p>
              {t(TEXT?.SAVE)} {t(TEXT?.AND)} {t(TEXT?.DELIVER_HERE)}
            </p>
          )}
        </Button>
        <Button
          type="button"
          variant="outline"
          onClick={() => {
            setSelectedAddress(null);
            setEditingAddress(false);
            setAddingNewAddress(false);
            setShowConformationMail(false);
          }}
        >
          {t(TEXT?.CANCEL)}
        </Button>
      </div>
    </div>
  );
};

export default DeliveryAddressForm;
